<div class="content-space">
  <div class="text-center">
    <div class="confirm-content">
      <h1 class="mb-8">Időpont foglalás elküldve!</h1>
      <p>Az előzetes időpontfoglalás sikeresen megtörtént, erről e-mail értesítést küldtünk. Időpontja az
        elfogadás után válik véglegessé, melyet 24 órán belül újabb e-mailben küldünk meg Önnek.
        Addig is szíves türelmét kérjük!</p>
      <p class="my-2">Probléma vagy kérdés esetén keressen minket a <a class="inline">fizioeref@gmail.com</a> címen.</p>
    </div>
  </div>
</div>
