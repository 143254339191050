<div class="text-center">
  <h1 class="text-center mt-16">Adatok megadása</h1>
  <div class="data">
    <div class="details" *ngIf="time">
      <div><b>Probléma:</b> {{problem.name}}</div>
      <div class="time"><b>Időpont:</b>{{time.year}}. {{time | datePipe}}. {{time.hour}}:{{time.minute}}</div>
      <div *ngIf="problem.doctor"><b>Gyógytornász:</b> {{doctor.name}}</div>
      <div><b>Helyszín:</b> Székesfehérvár, <br/> Budai út 46. 2/5.</div>
    </div>
    <div class="form">
      <form #patientForm="ngForm">
        <label for="patientName">Név *</label>
        <input type="text" required id="patientName" name="patientName" [(ngModel)]="patient.name"
               #patientName="ngModel">

        <label for="patientEmail">Email cím *</label>
        <input type="email" required id="patientEmail" name="patientEmail" [email]="true"
               [(ngModel)]="patient.email" #patientEmail="ngModel">

        <label for="phone">Telefonszám *</label>
        <input type="tel" id="phone" required name="phone" pattern="[- +()0-9]+"
               [(ngModel)]="patient.phoneNumber" #phone="ngModel">

        <div *ngIf="problem.name === 'Egyéb'">
          <label for="problemText">Probléma * (Egyéb kategória)</label>
          <textarea id="problemText" name="problemText" required [(ngModel)]="moreProblem"
                    #problemText="ngModel"></textarea>
        </div>

        <label for="otherText">Megjegyzés / Kérdés</label>
        <textarea id="otherText" name="otherText" [(ngModel)]="other" #otherText="ngModel"></textarea>

        <input type="checkbox" required id="data" name="data" [(ngModel)]="agree" #data="ngModel"
               [checked]="agree">
        <label class="ml-2" for="data" (click)="agree = !agree">Elolvastam és elfogadom az </label>
        <a target="_blank" class="inline" href="assets/files/adatkezelesi-tajekoztato.pdf">Adatkezelési tájékoztatót.</a>
      </form>
      <p class="note">A * jelölt mezők megadása kötelező.</p>
    </div>
  </div>
</div>
<div class="btn-container">
  <button class="default-button" (click)="clickBack()">Vissza</button>
  <button class="primary-button" [disabled]="!patientForm.valid || isLoading" (click)="clickNext()">Foglalás
    <app-loading *ngIf="isLoading" [isWhite]="true"></app-loading>
  </button>
</div>
