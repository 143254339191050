<div class="content-space">
  <div class="bs-stepper">
    <div class="bs-stepper-header">
      <div class="step" data-target="#problem">
        <button class="step-trigger">
          <span class="bs-stepper-circle" *ngIf="currentIndex === 0">1</span>
          <span class="bs-stepper-circle" *ngIf="currentIndex >= 1">✔</span>
          <span class="bs-stepper-label">Probléma</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#time">
        <button class="step-trigger">
          <span class="bs-stepper-circle" *ngIf="currentIndex <= 1">2</span>
          <span class="bs-stepper-circle" *ngIf="currentIndex > 1">✔</span>
          <span class="bs-stepper-label">Időpont</span>
        </button>
      </div>
      <div class="line"></div>
      <div class="step" data-target="#data">
        <button class="step-trigger">
          <span class="bs-stepper-circle" *ngIf="currentIndex <= 2">3</span>
          <span class="bs-stepper-circle" *ngIf="currentIndex > 2">✔</span>
          <span class="bs-stepper-label">Adatok</span>
        </button>
      </div>
    </div>
    <div class="bs-stepper-content">
      <div id="problem" class="content">
        <app-problem (next)="next()" (back)="back()"></app-problem>
      </div>
      <div id="time" class="content">
        <app-time (next)="next()" (back)="back()"></app-time>
      </div>
      <div id="data" class="content text-center">
        <app-data (next)="navigateToConfirmPage($event)" (back)="back()"></app-data>
      </div>
    </div>
  </div>

</div>
