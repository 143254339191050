<div class="content-space">
<h2>
    Torna nyugdíjasoknak
  </h2>
  <div class="divider"></div>
  <div>
    Minden <b>KEDDEN 14:00 - 14:45-ig*</b> egész testet átmozgató, csoportos órát tartunk nyugdíjasoknak.
    <br/>
    <br/><b>Helyszín:</b> Felsővárosi közösségi ház (8000 Székesfehérvár, Havranek u. 27.)
    <br/><b>Egy alkalom:</b> 1500 Ft
    <br/><b>4 alkalmas bérlet:</b> 5000 Ft<br/>
    <br/>
    <span class="text-sm">* Bejelentkezés nem szükséges</span>
  </div>
</div>
