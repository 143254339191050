import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ReservationService } from '../../core/reservation.service';
import { Doctor, Patient, PendingEvent, Problem } from '../types/types';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { NotificationService } from '../../core/notification.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import {AnalyticsService} from "../../core/analytics.service";

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  patient: Patient = {} as Patient;
  other: string = '';
  moreProblem: string = '';
  agree: boolean = false;

  problem: Problem = new Problem();
  time: DateTime | undefined;
  doctor: Doctor = new Doctor();

  isLoading: boolean = false;

  constructor(private reservationService: ReservationService, private notificationService: NotificationService,
              private gaService: GoogleAnalyticsService, private analyticsService: AnalyticsService) {
  }

  ngOnInit(): void {
    this.reservationService.problemSubject.subscribe(resp => this.problem = resp);
    this.reservationService.timeSubject.subscribe(resp => this.time = resp);
    this.reservationService.doctorSubject.subscribe(resp => this.doctor = resp);
  }

  async clickNext() {
    try {
      const gclid = sessionStorage.getItem('gclid');
      const fbclid = sessionStorage.getItem('fbclid');

      this.isLoading = true;

      await this.createPendingEvent();

      if(!!gclid) {
        this.gaService.gtag('event', 'conversion', {
          'send_to': 'AW-16721761780/UlnLCP6ozNgZEPSrx6U-',
          'category': this.problem.category
        });
      }

      if(!!fbclid) {
        const sendFacebookEvent$ = this.analyticsService.sendFacebookEvent('CompleteRegistration', fbclid);
        await lastValueFrom(sendFacebookEvent$);
      }

      this.next.emit(this.problem.category);
    } catch (e) {
      console.log(e);
      this.notificationService.showErrorModal('Nem sikerült elküldeni a foglalást. Kérjük próbálja újra, vagy kerssen emailben, telefonon bennünket.')
    } finally {
      this.isLoading = false;
    }
  }

  clickBack() {
    this.back.emit();
  }

  async createPendingEvent() {
    const pendingEvent = new PendingEvent(
      this.time, this.problem, this.doctor, this.patient, this.other + this.moreProblem);
    await lastValueFrom(this.reservationService.createPendingEvent(pendingEvent));
  }
}
