import { Component } from '@angular/core';

@Component({
  selector: 'app-cpm',
  templateUrl: './cpm.component.html',
  styleUrls: ['./cpm.component.scss']
})
export class CpmComponent {

}
